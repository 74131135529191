<script>
import LatexData from "@/components/LatexData.vue";
import axios from "axios";

export default {
  name: "TaskAnswer",
  components: {LatexData},
  created() {
    this.getAnswers();
  },
  props: {
    questionId: String,
    taskId: String,
    correctAnswerFromParent: String
  },
  data() {
    return {
      answers: [
        {
          name: 'A',
          label: 'A',
          value: '$$'
        },
        {
          name: 'B',
          label: 'B',
          value: '$$'
        },
        {
          name: 'C',
          label: 'C',
          value: '$$'
        }, {
          name: 'D',
          label: 'D',
          value: '$$'
        }
      ],
      fetchedAnswers: [],
      correctAnswer: ''
    }
  },
  methods: {
    async getAnswers() {
      const response = await axios.get('/tasks/getAnswers', {
        params: {
          questionId: this.questionId
        }
      });
      if(response.data.length > 0) {
        this.fetchedAnswers = response.data;
        this.correctAnswer = this.fetchedAnswers.find(answer => answer.isCorrect === 1)?.label;
      } else {
        this.correctAnswer = this.correctAnswerFromParent;
        this.fetchedAnswers = this.answers;
      }
    },
    renderAnswers(index, event) {
      this.$refs[index][0].processEqu(event.target.value);
    },
    addAnswer() {

      const lastAnswer = this.fetchedAnswers[this.fetchedAnswers.length - 1];
      const nextChar = String.fromCharCode(lastAnswer.label.charCodeAt(0) + 1);
      this.fetchedAnswers.push({
        name: nextChar,
        label: nextChar,
        value: '$$'
      });
    },
    async saveAnswers() {
      this.fetchedAnswers.forEach(answer => {
        if(answer.label === this.correctAnswer) {
          answer.isCorrect = true;
        }
      });
      const response = await axios.post('/tasks/replaceAnswers', {
        answers: this.fetchedAnswers,
        questionId: this.questionId,
        taskId: this.taskId,
        isTempQuestion: Number.isInteger(this.questionId)
      });
      if (response.data.status === 200) {
        this.$toast.add({severity: 'success', summary: 'Sukces', detail: 'Odpowiedzi zapisane', group: 'tc', life: 2000});
      }
    }
  }
}
</script>

<template>
  <Button class="mb-5 button-border" label="Zapisz odpowiedzi" icon="pi pi-check" @click="saveAnswers"/>
  <div class="field">
          <span class="p-float-label">
            <InputText id="correctAnswer" v-model="correctAnswer" type="text" class="input"/>
            <label for="correctAnswer">Poprawna odpowiedź</label>
          </span>
  </div>
<!--  <div class="grid">
    <template v-for="(answer, index) in fetchedAnswers">
      <div class="col-6">
        <div class="field">
               <span class="p-float-label">
                <InputText :id="answer.id" v-model="answer.value" type="text" class="input"
                           @input="renderAnswers(index, $event)"/>
                <label :for="answer.id">{{ answer.label }}</label>
               </span>
        </div>
      </div>
      <div class="col-6">
        <LatexData :ref="index" :equ="answer.value"></LatexData>
      </div>
    </template>
  </div>-->
  <div class="grid">
    <template v-for="(answer, index) in fetchedAnswers">
      <div class="col-6">
               <span class="p-float-label">
                <Textarea :id="answer.id" v-model="answer.value" type="text" class="textarea"
                           @input="renderAnswers(index, $event)"></Textarea>
                <label :for="answer.id">{{ answer.label }}</label>
               </span>
        <LatexData :ref="index" :equ="answer.value"></LatexData>
      </div>

    </template>
  </div>
  <div>
    <Button class="mb-5 answer-button button-border" label="Dodaj odpowiedź" @click="addAnswer"/>
  </div>

</template>

<style scoped>
/*.field {
  margin-bottom: 1.5em;
}*/

.input {
  width:20rem;
  margin-bottom: 1rem;
}
.textarea {
  min-width: 20rem;
  min-height: 6rem;
}
.answer-button {
  width: 20rem;
}
</style>
