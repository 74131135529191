<script>
import SignupModal from "@/components/SignupModal.vue";
import LoginModal from "@/components/LoginModal.vue";
import axios from "axios";

export default {
  name: 'App',
  components: {LoginModal, SignupModal},
  created() {
    this.items = this.menuDefaultItems;
    this.checkUser();
  },
  updated() {
    this.checkUser();
  },
  data() {
    return {
      selectedTask: {},
      showLoginRegisterButtons: false,
      showUserSection: false,
      showSignupModal: false,
      showLoginModal: false,
      userType: '',
      showAdminButton: false,
      user: '',
      menuDefaultItems: [
        {
          label: 'Home',
          icon: 'pi pi-home',
          route: '/'
        },
        {
          label: 'Losuj zadanie',
          icon: 'pi pi-pencil',
          route: '/losuj-zadanie'
        },
        {
          label: 'Teoria',
          icon: 'pi pi-calculator',
          route: '/teoria'
        },
        {
          label: 'Arkusze maturalne',
          icon: 'pi pi-book',
          route: '/arkusze-maturalne'
        },
        {
          label: 'Aktualności',
          icon: 'pi pi-star',
          route: '/aktualnosci'
        }
      ],
      items: this.menuDefaultItems,
      adminItems: [
        {
          label: 'Home',
          icon: 'pi pi-home',
          route: '/'
        },
        {
          label: 'Losuj zadanie',
          icon: 'pi pi-pencil',
          route: '/losuj-zadanie'
        },
        {
          label: 'Teoria',
          icon: 'pi pi-calculator',
          route: '/teoria'
        },
        {
          label: 'Arkusze maturalne',
          icon: 'pi pi-book',
          route: '/arkusze-maturalne'
        },
        {
          label: 'Aktualności',
          icon: 'pi pi-star',
          route: '/aktualnosci'
        },
        {
          label: 'Admin',
          icon: 'pi pi-shield',
          route: '/admin'
        }/*,
        {
          label: 'Wyzwania',
          icon: 'pi pi-crown',
          route: '/wyzwania'
        }*/
      ],
      userItems: [
        {
          label: 'Profil',
          command: () => {
            this.handleProfileSettings();
          }
        },
        {
          label: 'Wyloguj',
          icon: 'pi pi-sign-out',
          command: () => {
            this.logout();
          }
        }
      ],
      disableSingUpButton: false
    }
  },
  methods: {
    handleShowLoginModal() {
      this.showLoginModal = !this.showLoginModal;
    },
    handleShowSignupModal() {
      this.showLoginModal = false;
      this.showSignupModal = !this.showSignupModal;
    },
    handleSignupCompleted() {
      this.$forceUpdate();
      this.handleShowSignupModal();
    },
    handleLoginCompleted() {
      this.$router.push('/losuj-zadanie');
      this.$forceUpdate();
      this.handleShowLoginModal();
    },
    showToastMessage(event) {
      this.$toast.add({
        severity: event.severity,
        summary: event.summary,
        detail: event.detail,
        group: 'tc',
        life: event.life
      });
    },
    async checkUser() {
      this.showUserSection = false;
      const url = new URL(window.location.href);
      if(url.host.includes('localhost')
          || url.host.includes('dev')) {
        //this.disableSingUpButton = true;
      }
      const token = this.getCookie('lz_token');
      if (token) {
        const response = await axios.get('/users/checkUser', {
          headers: {
            Authorization: token
          }
        });
        this.user = response.data;
        if (this.user) {
          this.showLoginRegisterButtons = false;
          this.showUserSection = true;
          if (this.user.type === 'admin') {
            this.items = this.adminItems;
          }
        } else {
          this.showLoginRegisterButtons = true;
        }
      } else {
        this.showLoginRegisterButtons = true;
      }
    },
    getCookie(name) {
      return this.$cookies.get(name);
    },
    handleUserButton(event) {
      this.$refs.user.toggle(event);
    },
    handleProfileSettings() {
      this.$router.push('/profil');
    },
    async logout() {
      const token = this.getCookie('lz_token');
      await axios.post('/users/logout', {
        token: token
      });
      this.$cookies.remove('lz_token');
      this.items = this.menuDefaultItems;
      this.$forceUpdate();
      this.$router.push('/')
    },
    handleOpenTerms() {
      this.$router.push('/regulamin');
    },
    handleOpenPolicy() {
      this.$router.push('/polityka-prywatnosci');
    },
    handleOpenPriceList() {
      this.$router.push('/cennik');
    },
    handleOpenContact() {
      this.$router.push('/kontakt');
    }
  }
}
</script>

<template>
  <Toast position="top-center" group="tc"/>
  <Menubar :model="items" class="menubar">
    <template #start>
      <div class="start-container">
        <a href="/" class="logo-link text-center">
            <img src="./assets/calculator.png" class="logo">
            losujzadanie.pl
        </a>
      </div>
    </template>
    <template #item="{ item, props }">
      <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
        <a v-bind="props.action" @click="navigate">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </router-link>
    </template>
    <template #end>
      <div class="flex">
        <div v-if="showLoginRegisterButtons" class="buttons-container">
          <div class="lg-container">
            <Button class="ml-2 navbar-button button-border" label="Logowanie" @click="handleShowLoginModal"
                    icon="pi pi-sign-in"></Button>

            <Button class="ml-2 navbar-button button-border" label="Rejestracja" @click="handleShowSignupModal"
                    icon="pi pi-user-plus" v-if="disableSingUpButton === false"></Button>
          </div>
          <div class="sm-container">
            <Button class="ml-2" @click="handleShowLoginModal" rounded
                    icon="pi pi-sign-in icon-no-label"></Button>
          </div>
        </div>
        <div v-if="showUserSection" aria-controls="overlay_menu">
          <Button class="username" :label="user.username" icon="pi pi-user" text @click="handleUserButton"></Button>
          <Menu ref="user" id="overlay_menu" :model="userItems" :popup="true"/>
        </div>

      </div>
    </template>
  </Menubar>
  <router-view v-slot="{Component}">
    <transition name="fade">
      <component :is="Component"/>
    </transition>
  </router-view>
  <div class="footer">
    <div class="pt-2">
      <Button class="footer-link" label="Regulamin" link @click="handleOpenTerms"/>
      <Button class="footer-link" label="Polityka prywatności" link @click="handleOpenPolicy"/>
      <Button class="footer-link" label="Cennik" link @click="handleOpenPriceList"/>
      <Button class="footer-link" label="Kontakt" link @click="handleOpenContact"/>
    </div>
    <div class="copyright">
      <p>© Copyrights 2024 losujzadanie.pl Anna & Przemysław Bilińscy</p>
    </div>
  </div>
  <Dialog v-model:visible="showSignupModal" modal header="Rejestracja" :style="{ width: '25rem' }"
          :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <SignupModal @show-modal="handleShowSignupModal" @register-completed="handleSignupCompleted"
                 @show-toast="showToastMessage"></SignupModal>
  </Dialog>
  <Dialog v-model:visible="showLoginModal" modal header="Logowanie" :style="{ width: '25rem' }"
          :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <LoginModal @show-modal="handleShowLoginModal" @login-completed="handleLoginCompleted"
                @show-toast="showToastMessage" @show-signup="handleShowSignupModal"></LoginModal>
  </Dialog>
</template>

<style>
body {
  background-image: url('./assets/math-bg.png');
  background-repeat: repeat;
  padding: 0;
  margin: 0;
}

.start-container {
  margin-left: 3em;
  color: #8B5CF6;
  width: 200px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.logo{
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.logo-link {
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 50px;
}
.start-container:hover {
  background: #f3f4f6;
  border-radius: 6px;
}
.logo-link:hover {
  color: #8B5CF6;
}
.menubar {
  border-radius: 0;
  border: none;
  margin: auto;
}
.p-menubar .p-menubar-custom {
  float: right;

}
.p-menuitem-link {
/*  color: #8B5CF6;*/
  /*font-weight: 600;*/
  font-size: 1rem;
}
a:visited,a:active, a:link {
  color: inherit;
  text-decoration: none;
}

.card {
  display: flex;
  margin-bottom: 1rem;
}
.footer {
  height: 6rem;
  color: #4b5563;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background-color: white;
  position: relative;
  margin-top: 37em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.icon {
  font-size: 1.5em;
}

.contact-link {
  color: #4b5563;
  padding: 2rem;
}

.contact-link:hover {
  color: #bebdbd;
}

.footer-link {
  font-size: 0.8rem;
}

.sm-container {
  display: none;
}
.card-center {
  justify-content: center;
  align-items: center;
}
.card-panel {
  margin-top: 1rem;
  justify-content: center;
  text-align: left;
  width: 100%;
}
.card-body {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 1rem;
  width: 80rem;
  min-height: 20rem;
}
h1,h2,h3,h4, p, label {
  color: #4b5563;
}
a:hover {
  color: #4b5580;
}
.MathJax {
  color: #4b5563;
}
.p-checkbox-icon {
  color: white;
}
.p-dialog-header{
  color: #4b5563;
}
.button-border {
  border-radius: 50px;
}

@media screen and (max-width: 640px) {
  body {
    min-height: 80vh;
  }

  .username {
    font-size: 1rem;
  }

  .footer {
    height: 6rem;
    margin-top: 26rem;
  }

  .start-container {
    display: none;
  }

  .navbar-button {
    width: 50%;
    font-size: 0.8em;
    margin-bottom: 1em;
  }

  .buttons-container {
    text-align: right;
  }
  .icon-no-label {
    font-weight: bold;
    font-size: 1.5em;
  }

  .copyright {
    font-size: 0.8em;
  }

  .footer-link {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .card-panel {
    width: 100%;
  }
  .card-body {
    width: 100%;
    padding: 1rem;
  }
}
@media screen and (max-width: 1366px) {
  .lg-container {
    display: none;
  }
  .sm-container {
    display: block;
  }

}
</style>
