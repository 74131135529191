<script>
export default {
  name: "LatexData",
  props: ['equ', 'class'],
  emits:['equ-rendered'],
  created() {
    this.processEqu(this.equ);
  },
  data() {
    return {
      showEqu: false,
      equToDisplay: [],
      mathClass: ''
    }
  },
  methods: {
    processEqu(equ) {
      this.mathClass = this.class ? this.class : 'math';
      let subString = equ.split(';;');
      let subStringAsObjects = [];
      subString.forEach(singleText => {
        singleText = singleText.replace('\n', '');
        if(singleText.startsWith('$')) {
          subStringAsObjects.push({
            type: 'math-block',
            text: singleText
          });
        } else if(singleText.includes('$')) {
          subStringAsObjects.push({
            type: 'math-inline',
            text: singleText
          });
        } else if(singleText.includes('https:')) {
          subStringAsObjects.push({
            type: 'img',
            text: singleText
          });
        } else {
          subStringAsObjects.push({
            type: 'p',
            text: singleText
          });
        }
      });
      this.solutionAsArray = subStringAsObjects;
      subStringAsObjects.forEach(singleEqu => {
        if (singleEqu.type === 'math-inline') {
          singleEqu.substring = singleEqu.text.split('$');
        } else if (singleEqu.type === 'math-block') {
          singleEqu.text = singleEqu.text.replaceAll('$', '');
        }
      });
      this.equToDisplay = subStringAsObjects;
      this.$emit('equ-rendered');
    }
  }
}
</script>

<template>
  <div v-for="singleEqu of equToDisplay" v-bind:key="equ.text">
    <p v-if="singleEqu.type === 'p'"  :class="mathClass">
      {{ singleEqu.text }}
    </p>
    <p v-if="singleEqu.type === 'math-inline'"  :class="mathClass">
      <template v-for="subText of singleEqu.substring" v-bind:key="subText">
        <math-jax v-if="subText.includes('\\')" :latex="subText"></math-jax>
        <template v-else>{{ subText }}</template>
      </template>
    </p>
    <math-jax v-if="singleEqu.type === 'math-block'" :class="mathClass" :latex="singleEqu.text" :block="true"></math-jax>
    <div class="image-container" v-if="singleEqu.type === 'img'">
      <img :src="singleEqu.text"  alt="img.png" class="image">
    </div>
  </div>
</template>

<style scoped>
p {
  font-size: 1.2rem;
  line-height: normal;
}
.math {
  font-size: 1.2rem;
}
.small-font {
  font-size: 1rem;
}
.latex-a {
  font-size: 1.5rem;
}
.image-container {
  width: 40%;
 margin-left: auto;
  margin-right: auto;
}
.image{
  width: 100%;
}
@media screen and (max-width: 640px) {
  p {
    font-size: 0.8rem;
  }
  .math {
    font-size: 0.7rem;
  }
  .latex-a {
    font-size: 0.8rem;
  }
  .image-container {
    width: 50%;
  }
}
</style>