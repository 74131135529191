<script>
import axios from "axios";

export default {
  name: "Updates",
  data() {
    return {
      updates: []
    }
  },
  async created() {
    const response = await axios.get('/getUpdates');
    this.updates = response.data.map(record => {
      let date = new Date(record.date).toLocaleDateString();
      return {
        id: record.id,
        date: date,
        details: record.details
      }
    });
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <h2 class="default-color">Aktualności</h2>
      <div class="container">
        <DataTable :value="updates" tableStyle="min-width: 50rem" resizableColumns columnResizeMode="fit"
                   size="small" paginator :rowsPerPageOptions="[10,25,50]" :rows="25"
                   paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                   currentPageReportTemplate="{first} - {last} z {totalRecords}">
          <Column field="date" header="Data"></Column>
          <Column field="details" header="Opis"></Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>