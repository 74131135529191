<script>
import ReportModal from "@/components/ReportModal.vue";
import TaskForm from "@/components/TaskForm.vue";
import LatexData from "@/components/LatexData.vue";
import CommentParent from "@/components/CommentParent.vue";

export default {
  name: "TaskCard",
  components: { LatexData, TaskForm, ReportModal, CommentParent },
  emits: ['show-answer', 'add-favorite-task', 'remove-favorite-task'],
  props: {
    task: Object,
    showAnswer: Boolean,
    isFavoriteTask: Boolean,
    hideButtons: Boolean,
    customerId: String
  },
  created() {
    this.refreshImages();
  },
  data() {
    return {
      showSolutionImage: false,
      showModal: false,
      message: '',
      reportButtonDisabled: false,
      solutionImage: '',
      yearFolder: '',
      taskFolder: '',
      qUrl: '',
      aUrl: '',
      sUrl: '',
      imgUrl: '',
      aClass: '',
      sClass: '',
      solutionAsImg: false,
      answerAsImg: false,
      showComments: false
    }
  },
  methods: {
    show() {
      let tempShowAnswer = !this.showAnswer;
      this.$emit('show-answer', tempShowAnswer);
    },
    handleEquRendered() {
      this.showComments = true;
    },
    handleShowModal() {
      this.showModal = !this.showModal;
    },
    handleMessageChange(event) {
      this.message = event;
      this.handleShowModal();
    },
    refreshImages() {
      this.qUrl = this.task.qUrl;
      this.aUrl = this.task.aUrl;
      this.imgUrl = this.task.imgUrl;
      this.sUrl = this.task.sUrl;
    },
    handleAddToFavoriteTasks() {
      this.$emit('add-favorite-task', this.task);
    },
    handleRemoveFromFavoriteTasks() {
      this.$emit('remove-favorite-task', this.task.id);
    }
  }
}
</script>

<template>
  <Toast position="top-center" group="tc"/>
  <div class="card card-panel">
    <div class="card-body">
      <div v-if="isFavoriteTask" class="favorite-container">
        <Button class="ml-2 button-no-label fav-button" text @click="handleRemoveFromFavoriteTasks">
          <i class="pi pi-heart-fill pi-icon"></i>
        </Button>
        <p class="favorite-p">W ulubionych</p>
      </div>
      <div v-else class="favorite-container">
        <Button class="ml-2 button-no-label fav-button" text @click="handleAddToFavoriteTasks">
          <i class="pi pi-heart pi-icon"></i>
        </Button>
        <p class="favorite-p">Dodaj do ulubionych</p>
      </div>
      <div class="card-container">
        <div class="center-container">
          <h2 class="title">Zadanie {{ task.taskNumber }}.</h2>
          <div class="task-image">
            <img :src="qUrl" :alt="`Zadanie: ${task.id}`" class="image-q"/>
          </div>
        </div>
        <div class="label">
          <p v-if="task.isFromExam === 1" class="task-label">{{ task.label }}</p>
        </div>
        <div class="card-answer fadein" v-if="showAnswer">
          <div class="answer">
            <div class="center-container">
              <h2 class="header">Odp:</h2>
            </div>
            <div class="center-container">
              <LatexData :equ="task.aUrl" class="latex-a"></LatexData>
            </div>
          </div>
          <div v-if="task.showSolutionImg">
            <div class="center-container">
              <h2 class="header">Rysunek pomocniczy:</h2>
              <div>
                <img :src="imgUrl" :alt="`Rysunek pomocniczy: ${task.id}`" class="image"/>
              </div>
            </div>
          </div>
          <div v-if="task.showSolution === 1">
            <div class="center-container">
              <h2 class="header">Rozwiązanie:</h2>
            </div>
            <div>
              <div class="latex-container">
                <LatexData :equ="task.sUrl" @equ-rendered="handleEquRendered"></LatexData>
              </div>
            </div>
            <CommentParent v-if="showComments" :task-id="task.id" :customer-id="customerId"></CommentParent>
          </div>
        </div>
        <div v-if="hideButtons === false" class="card-footer">
          <div>
            <Button class="button button-border" label="Pokaż odpowiedź" @click="show" icon="pi pi-check"></Button>
          </div>
<!--          <p class="or-label">lub</p>
          <div>
            <Button class="button" label="Zgłoś zadanie" @click="handleShowModal" icon="pi pi-exclamation-circle"
                    severity="danger"></Button>
          </div>-->
        </div>
        <div v-else>
          <div class="text-center mt-5">
            <h3>Rozwiązania zadań są dostepne w pakiecie darmowym lub płatnym po zalogowaniu.</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="showModal" modal header="Zgłoś zadanie" :style="{ width: '25rem' }"
          :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <ReportModal :taskNumber="task.id" @send-message="handleMessageChange"></ReportModal>
  </Dialog>
</template>

<style scoped>
.card-container {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.task {
  font-size: 1.5rem;
}

.title {
  margin-top: 0;
  font-size: 1.5rem;
  background-color: #d5b8ea;
  padding: 0.2rem 0;
}

.label {
  text-align: center;
}

.task-label {
  font-size: 0.9em;
  color: #8B5CF6;
  font-style: italic;
  font-weight: 500;
}

.card-footer {
  margin-top: 2rem;
  text-align: center;
}

.card-answer {
  margin-bottom: 1rem;
}

.task-image {
  width: 100%;
  justify-content: center;
}

.image {
  max-width: 100%;
}

.or-label {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.button {
  width: 20em;
}

.fav-button {
  padding: 0 !important;
}

.favorite-container {
  text-align: right;
  height: 3.5rem;
  font-size: 0.8rem;
}

.favorite-p {
  margin-top: 0;
  margin-bottom: 0;
}

.button-no-label {
  padding-bottom: 0.5rem;
}

.pi-icon {
  padding-bottom: 0;
  font-size: 1.5rem;
}

.center-container {
  text-align: center;
}

@media screen and (max-width: 640px) {
  .card-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .image {
    max-width: 100%;
  }

  .image-q {
    max-width: 100%;
  }

  .task-label {
    font-size: 0.6em;
  }

  .header {
    font-size: 1em;
  }

  .title {
    padding: 0.1rem 0;
    font-size: 1rem;
  }

  .footer-container {
    display: grid;
    place-items: center;
  }

  .button {
    width: 100%;
    font-size: 1rem;
  }

  .or-label {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .favorite-p {
    display: none;
  }

  .latex-container {
    width: 100%;
  }
}

.fadein {
  animation-name: fadein-animation;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
}

@keyframes fadein-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>