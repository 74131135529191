<script>
import TaskCardView from "@/components/TaskCardView.vue";
import CommentParent from "@/components/CommentParent.vue";
import axios from "axios";

export default {
  name: "TaskContainer",
  components: {TaskCardView},
  created() {
    this.showTask();
  },
  data() {
    return {
      taskId: '',
      user: '',
      //comments: []
    }
  },
  methods: {
    async checkUser() {
      const token = this.getCookie('lz_token');
      if (token) {
        const response = await axios.get('/users/checkUser', {
          headers: {
            Authorization: token
          }
        });
        this.user = response.data;
      }
      if (!this.user
          || this.user.isPremiumUser === 0
          || this.user.freePlanActivated === 1) {
        this.$router.push('/arkusze-maturalne');
      }
    },
    async showTask() {
      this.taskId = this.$route.params.name;
      await this.checkUser();
    },
    handleBack() {
      this.$router.go(-1);
    },
    getCookie(name) {
      return this.$cookies.get(name);
    }
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <div class="container">
        <Button class="button" text label="Powrót" @click="handleBack"></Button>
        <TaskCardView :task-id="taskId" :customer-id="user.customerId"></TaskCardView>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button {
  padding-left: 0;
  font-weight: 700;
}

@media screen and (max-width: 640px) {
  .button {
    font-size: 0.8rem;
  }
}
</style>