import { createRouter, createWebHistory  } from 'vue-router'
import About from '../views/About.vue';
import Home from '../views/Home.vue';
import Admin from '../views/Admin.vue';
import Updates from '../views/Updates.vue';
import MainPage from '../views/MainPage.vue';
import ResetPassword from '../views/ResetPassword.vue';
import PaymentSuccess from '../views/PaymentSuccess.vue';
import PaymentCancel from '../views/PaymentCancel.vue';
import Profile from '../views/Profile.vue';
import Terms from '../views/Terms.vue';
import Policy from '../views/Policy.vue';
import PriceList from "@/views/PriceList.vue";
import Education from "@/views/Education.vue";
import Subunit from "@/views/Subunit.vue";
import Requirements from "@/views/Requirements.vue";
import ExamSheets from "@/views/ExamSheets.vue";
import SingleExamSheet from "@/views/SingleExamSheet.vue";
import TaskContainer from "@/views/TaskContainer.vue";
import Contact from "@/views/Contact.vue";
import ConfirmEmail from "@/views/ConfirmEmail.vue";
/*import Challenges from "@/views/Challenges.vue";
import InProgressChallenge from "@/views/InProgressChallenge.vue";*/


const routes = [
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/losuj-zadanie',
    name: 'losujzadanie',
    component: Home
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin
  },
  {
    path: '/aktualnosci',
    name: 'aktualnosci',
    component: Updates
  },
  {
    path: '/',
    name: 'home',
    component: MainPage
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/payment/success',
    name: 'paymentSuccess',
    component: PaymentSuccess
  },
  {
    path: '/payment/cancel',
    name: 'paymentCancel',
    component: PaymentCancel
  },
  {
    path: '/profil',
    name: 'profil',
    component: Profile
  },
  {
    path: '/regulamin',
    name: 'regulamin',
    component: Terms
  },
  {
    path: '/polityka-prywatnosci',
    name: 'polityka-prywatnosci',
    component: Policy
  },
  {
    path: '/cennik',
    name: 'cennik',
    component: PriceList
  },
  {
    path:'/teoria',
    name: 'teoria',
    component: Education
  },
  {
    path:'/teoria/:name',
    name: 'dzial',
    component: Subunit
  },
  {
    path:'/wymagania2025',
    name: 'wymagania',
    component: Requirements
  },
  {
    path:'/arkusze-maturalne',
    name: 'arkusze-maturalne',
    component: ExamSheets
  },
  {
    path:'/arkusze-maturalne/:name',
    name: 'arkusze',
    component: SingleExamSheet
  },
  {
    path:'/arkusze-maturalne/zadanie/:name',
    name: 'zadanie',
    component: TaskContainer
  },
  {
    path:'/kontakt',
    name: 'kontakt',
    component: Contact
  },
  {
    path: '/confirmEmail',
    name: 'confirmEmail',
    component: ConfirmEmail
  },
 /* {
    path:'/wyzwania',
    name: 'wyzwania',
    component: Challenges
  },
  {
    path:'/wyzwanie/:id',
    name: 'wyzwanie',
    component: InProgressChallenge
  },*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
      return savedPosition || new Promise((resolve => {
       setTimeout(() => resolve ({top: 0}), 0)
      }))
  }
})

export default router
